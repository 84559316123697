module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Umami Food Magazine","short_name":"Umami","description":"Kick off your next, great Gatsby project with this default starter. This barebones starter ships with the main Gatsby configuration files you might need.","lang":"en","start_url":"/en","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","include_favicon":false,"localize":[{"name":"Revista Umami Food","short_name":"Umami","description":"Comienza tu próximo gran proyecto de Gatsby con este motor de arranque predeterminado. Este iniciador básico se envía con los principales archivos de configuración de Gatsby que pueda necesitar.","lang":"es","start_url":"/es"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"redirect":true,"i18nextOptions":{"debug":"false","resources":{},"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang/recipes/:slug","getLanguageFromPath":true},{"matchPath":"/:lang/articles/:slug","getLanguageFromPath":true},{"matchPath":"/:lang/recipe-category/:slug","getLanguageFromPath":true},{"matchPath":"/:lang/tags/:slug","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"exclude":["**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**","**/user/"],"excludeOptions":{"separator":"."},"crumbLabelUpdates":[{"pathname":"/en","crumbLabel":"Home"},{"pathname":"/es","crumbLabel":"Inicio"},{"pathname":"/en/search","crumbLabel":"Search"},{"pathname":"/es/search","crumbLabel":"Buscar"},{"pathname":"/en/articles","crumbLabel":"Articles"},{"pathname":"/es/articles","crumbLabel":"Artículos"},{"pathname":"/en/recipes","crumbLabel":"Recipes"},{"pathname":"/es/recipes","crumbLabel":"Recetas"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W57VBGL","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

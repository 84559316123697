// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pages-js": () => import("./../../../src/pages/pages.js" /* webpackChunkName: "component---src-pages-pages-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-search-help-js": () => import("./../../../src/pages/search/help.js" /* webpackChunkName: "component---src-pages-search-help-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user/[...].js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basic-page.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-recipe-category-js": () => import("./../../../src/templates/recipe-category.js" /* webpackChunkName: "component---src-templates-recipe-category-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

